import { ApiError } from '../../errors';

export class HttpClient {
    constructor (authService, baseURL) {
        this.authService = authService;
        this.baseURL = baseURL;
        this.isRefreshing = false;
        this.refreshSubscribers = [];
    }

    addRefreshSubscriber (callback) {
        this.refreshSubscribers.push(callback);
    }

    onRefreshSuccess (newToken) {
        this.refreshSubscribers.forEach(callback => callback(newToken));
        this.refreshSubscribers = [];
    }

    getEndpoint (path, params = {}) {
        const url = new URL(path);

        Object.entries(params).forEach(([key, value]) => {
            if (value !== undefined && value !== null) {
                url.searchParams.append(key, value);
            }
        });

        return url.toString();
    }

    createHeaders (customHeaders = {}, requiresAuth = true) {
        const headers = {
            'Content-Type': 'application/json',
            ...customHeaders,
        };

        if (requiresAuth) {
            const token = this.authService.getAccessToken();

            if (token) {
                headers.Authorization = `Bearer ${token}`;
            }
        }

        return headers;
    }

    async checkTokenAndRefreshIfNeeded (requiresAuth) {
        if (!requiresAuth) return;

        const token = this.authService.getAccessToken();

        if (token && this.authService.isTokenExpiringSoon(token)) {
            try {
                await this.authService.refreshTokens();
            } catch (error) {
                console.error('Failed to refresh token:', error);
            }
        }
    }

    async request (method, endpoint, options = {}) {
        const requiresAuth = options.requiresAuth !== false; // По умолчанию true

        if (requiresAuth) {
            await this.checkTokenAndRefreshIfNeeded(requiresAuth);
        }

        const response = await this.makeRequest(method, endpoint, options);
        const contentType = response.headers.get('content-type');

        if (response.ok) {
            if (contentType && contentType.includes('application/json')) {
                return await response.json();
            }

            return null;
        }

        const errorData = await response.json();

        if (requiresAuth && errorData.error === 'Недействительный токен' && !options.isRetry) {
            return this.handleTokenError(() =>
                this.request(method, endpoint, { ...options, isRetry: true }),
            );
        }

        throw new ApiError(errorData.error || 'Request failed');
    }

    async handleTokenError (retryRequest) {
        try {
            if (!this.isRefreshing) {
                this.isRefreshing = true;
                const newToken = await this.authService.refreshTokens();

                this.isRefreshing = false;
                this.onRefreshSuccess(newToken);
            }

            return new Promise(resolve => {
                this.addRefreshSubscriber(() => {
                    resolve(retryRequest());
                });
            });
        } catch (error) {
            this.isRefreshing = false;
            this.authService.clearAuth();
            throw error;
        }
    }

    async makeRequest (method, endpoint, options = {}) {
        const url = `${endpoint}`;
        const requiresAuth = options.requiresAuth !== false;
        const headers = this.createHeaders(options.headers, requiresAuth);

        if (options.body instanceof FormData) {
            delete headers['Content-Type'];
        }

        const config = {
            method,
            headers,
            ...options,
            body: options.body instanceof FormData ? options.body :
                options.body ? JSON.stringify(options.body) : undefined,
        };

        return fetch(url, config);
    }

    // HTTP методы
    async get (endpoint, options = {}) {
        return this.request('GET', endpoint, options);
    }

    async post (endpoint, data, options = {}) {
        return this.request('POST', endpoint, {
            ...options,
            body: data,
        });
    }

    async put (endpoint, data, options = {}) {
        return this.request('PUT', endpoint, {
            ...options,
            body: data,
        });
    }

    async delete (endpoint, options = {}) {
        return this.request('DELETE', endpoint, options);
    }
}
