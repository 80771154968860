const API_BASE_URL = process.env.VUE_APP_API_URL;

const createEndpoint = (path) => `${API_BASE_URL}${path}`;

const API_PATHS = {
    LOGIN: '/employees/login',
    LOGIN_ADMIN: '/employees/admin/login',
    MANAGEMENT_CONTACTS: '/management-contacts',
    EMPLOYEE_UPLOAD: '/employee-duties/upload',
    EMPLOYEE_DUTIES: '/employee-duties/external',
    EMPLOYEE_BIRTHDAYS: '/employees/birthdays',
    VACATION_DAYS: '/vacation-days/external',
    DEBTS_ANY: '/debts-any',
    DEBTS_DOCS: '/debts-docs',
    WORK_SCHEDULE: '/work-schedules',
    LEADERS: '/leaders',
    EMPLOYEE: '/employees',
    VACANCIES: '/vacancies',
    WORK_REST_SETTINGS: '/work-rest-settings',
    DINING_MENU: '/dining-menus',
    SET_PIN: '/employees/set-password',
    CHECK_PIN: '/employees/check-password',
};

export const API_ENDPOINTS = Object.freeze(
    Object.entries(API_PATHS).reduce((acc, [key, path]) => ({
        ...acc,
        [key]: createEndpoint(path),
    }), {}),
);
