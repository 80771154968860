import { AUTH_TYPES } from '@/modules/auth/features/employee-auth/constants';

const employeeAccountRoutes = [
    {
        path: '/employee/account',
        name: 'EmployeeAccount',
        component: () => import('@/modules/account/views/AccountView.vue'),
        meta: { requiresEmployeeAuth: true },
    },
    {
        path: '/employee/login',
        name: 'EmployeeLogin',
        component: () => import('@/modules/auth/views/LoginView.vue'),
        props: {
            isAdmin: false,
        },
        meta: { requiresEmployeeAuth: false },
    },
    {
        path: '/employee/set-pin',
        name: 'UserSetPin',
        component: () =>
            import(
                '@/modules/auth/features/employee-auth/components/SetPin.vue'
            ),
        props: {
            authType: AUTH_TYPES.EMPLOYEE,
        },
        meta: { requiresEmployeeAuth: false },
    },
    {
        path: '/employee/enter-pin',
        name: 'UserEnterPin',
        component: () =>
            import(
                '@/modules/auth/features/employee-auth/components/EnterPin.vue'
            ),
        props: {
            authType: AUTH_TYPES.EMPLOYEE,
        },
        meta: { requiresEmployeeAuth: false },
    },
];

export default employeeAccountRoutes;
