import { AUTH_TYPES } from './authTypes';

export const AUTH_ROUTES = {
    [AUTH_TYPES.ADMIN]: {
        success: '/admin/dashboard',
        login: '/admin/login',
    },
    [AUTH_TYPES.EMPLOYEE]: {
        success: '/employee/account',
        login: '/employee/login',
    },
};
