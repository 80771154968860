import {
    BaseAuthService,
} from './baseAuthService';
import { TOKEN_KEYS } from '../constants';
import { API_ENDPOINTS } from '@/shared/constants';

export const EmployeeAuthService = new BaseAuthService(
    TOKEN_KEYS.EMPLOYEE,
    API_ENDPOINTS.EMPLOYEE,
);
