import EmployeesView from '@/modules/employees/views/EmployeesView.vue';
import AdvancedView from '@/modules/employees/features/advanced/AdvancedView.vue';
import MenuView from '@/modules/employees/features/menu/MenuView.vue';
import StockView from '@/modules/employees/features/stock/StockView.vue';

export const employeeRoutes = [
    {
        name: 'employees',
        path: '/employees',
        component: EmployeesView,
    },
    {
        name: 'advanced',
        path: '/employees/advanced',
        component: AdvancedView,
    },
    {
        name: 'menu',
        path: '/employees/menu',
        component: MenuView,
    },
    {
        name: 'stock',
        path: '/employees/stock',
        component: StockView,
    },
];
