export const TOKEN_KEYS = {
    EMPLOYEE: {
        ACCESS: 'employee_access_token',
        REFRESH: 'employee_refresh_token',
        USER: 'employee_user',
        REMEMBER: 'employee_remember',
    },
    ADMIN: {
        ACCESS: 'admin_access_token',
        REFRESH: 'admin_refresh_token',
        USER: 'admin_user',
        REMEMBER: 'admin_remember',
    },
};
