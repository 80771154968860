<template>
    <HeaderNav :title="pageTitle" />
    <section
        ref="sectionEmployees"
        class="section_employees"
    >
        <EmployeeLink
            v-for="item in employeeLinks"
            :key="item.to"
            v-bind="item"
        />
    </section>
    <FooterNav />
</template>

<script setup>
import { ref, onMounted } from 'vue';
import FooterNav from '@/modules/layout/default/components/footer-nav/FooterNav.vue';
import HeaderNav from '@/modules/layout/default/components/header-nav/HeaderNav.vue';
import {
    EmployeeLink,
} from './components';

import advancedWorkers from '@/assets/elements/advanced_workers.svg';
import menu from '@/assets/elements/menu.svg';
import transportSchedule from '@/assets/elements/transport_schedule.svg';
import stock from '@/assets/elements/stock.svg';
// import birthday from "@/assets/elements/birthday.svg";
import personalAccount from '@/assets/elements/personal_account.svg';

const pageTitle = 'Для сотрудников';
const sectionEmployees = ref(null);

const employeeLinks = [
    {
        to: '/employees/advanced',
        imgSrc: advancedWorkers,
        title: 'Передовики',
        hasParallax: true,
    },
    {
        to: '/employees/menu',
        imgSrc: menu,
        title: 'МЕНЮ<br>столовой<br>ЛК',
        hasParallax: true,
    },
    {
        to: '/employees/schedule',
        imgSrc: transportSchedule,
        title: 'Расписание служебного трунспорта',
        hasParallax: true,
    },
    {
        to: '/employees/stock',
        imgSrc: stock,
        title: 'Акции конкурсы реклама',
        hasParallax: true,
    },
    {
        to: '/employee/login',
        imgSrc: personalAccount,
        title: 'Личный кабинет',
        hasParallax: false,
    },
];

onMounted(() => {
    sectionEmployees.value.addEventListener('wheel', handleWheel);
});

function handleWheel (event) {
    event.preventDefault();
    sectionEmployees.value.scrollLeft += event.deltaY;
}
</script>
