<template>
    <section class="section_emploees">
        <div class="header_emploee">
            <p class="characteristics_title_meter">
                Расписание служебного транспорта
            </p>
            <div class="box_button_header_emploee">
                <div class="box_button_back_emploee">
                    <RouterLink
                        to="/employees"
                        class="button_standart"
                    >
                        Вернуться
                    </RouterLink>
                </div>
                <ModalHeader />
            </div>
        </div>
        <div class="section_schedule_v2">
            <div class="box_button_indicators">
                <button
                    v-for="direction in ['there', 'back']"
                    :key="direction"
                    class="button_indicators"
                    :class="{ box_day_opasiti: currentDirection !== direction }"
                    @click="switchDirection(direction)"
                >
                    {{ direction === 'there' ? 'Туда' : 'Обратно' }}
                </button>
            </div>
            <div class="container_main_schedule">
                <div
                    v-for="(schedule, place) in filteredSchedule"
                    :key="place"
                    class="box_main_schedule"
                >
                    <div class="box_main_test_schedule">
                        <p class="number_box_services">
                            {{ getPlaceName(place) }}
                        </p>
                        <div class="box_time_schedule">
                            <p
                                v-for="time in schedule"
                                :key="time"
                                class="font_16_Montserrat_Light color_orange"
                            >
                                {{ time || "" }}
                            </p>
                        </div>
                    </div>
                    <div class="box_button_schedule_v2">
                        <RouterLink
                            :to="getLinkForPlace(place)"
                            class="button_gray"
                        >
                            Перейти
                        </RouterLink>
                    </div>
                    <div class="box_button_schedule_v3">
                        <RouterLink
                            :to="getLinkForPlace(place)"
                            class="button_gray"
                        >
                            <img
                                src="@/assets/elements/right_arrow.svg"
                                class="img_button_schedule_v3"
                                alt="Arrow"
                            />
                        </RouterLink>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import ModalHeader from '@/shared/components/modal-header/ModalHeader.vue';

export default {
    components: { ModalHeader },
    setup () {
        const currentDirection = ref(localStorage.getItem('direction') || 'there');
        const scheduleData = ref(null);

        const fetchSchedule = async () => {
            try {
                const response = await fetch('/schedule.json');

                if (response.ok) {
                    scheduleData.value = await response.json();
                } else {
                    console.error('Ошибка загрузки расписания');
                }
            } catch (error) {
                console.error('Ошибка:', error);
            }
        };

        const getDayOfWeek = (date) => {
            const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

            return days[date.getDay()];
        };

        const getCurrentTime = (date) => {
            return date.toTimeString().slice(0, 5);
        };

        const timeToMinutes = (time) => {
            const [hours, minutes] = time.split(':').map(Number);

            return hours * 60 + minutes;
        };

        const filteredSchedule = computed(() => {
            if (!scheduleData.value) return {};

            const currentDate = new Date();
            const currentDay = getDayOfWeek(currentDate);
            const currentTime = getCurrentTime(currentDate);
            const currentMinutes = timeToMinutes(currentTime);

            const schedule = scheduleData.value[currentDirection.value];

            return Object.fromEntries(
                Object.entries(schedule).map(([place, data]) => {
                    const times = data.schedule[currentDay] || [];
                    const filteredTimes = times.filter(time => timeToMinutes(time) > currentMinutes);

                    return [place, filteredTimes.length > 0 ? filteredTimes : ['']];
                }),
            );
        });

        const switchDirection = (direction) => {
            currentDirection.value = direction;
            localStorage.setItem('direction', direction);
        };

        const getLinkForPlace = (place) => {
            const placeData = scheduleData.value[currentDirection.value][place];

            return placeData && placeData.path ? placeData.path : '/employees/schedule/default';
        };

        const getPlaceName = (place) => {
            return currentDirection.value === 'there'
                ? `${place} - Лог. Компекс`
                : `Лог. Компекс - ${place}`;
        };

        onMounted(fetchSchedule);

        return {
            currentDirection,
            filteredSchedule,
            switchDirection,
            getLinkForPlace,
            getPlaceName,
        };
    },
};
</script>
