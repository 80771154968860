export const clickOutside = {
    mounted (el, binding) {
        el._clickOutside = (event) => {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event);
            }
        };

        document.addEventListener('click', el._clickOutside);
    },
    unmounted (el) {
        document.removeEventListener('click', el._clickOutside);
    },
};
