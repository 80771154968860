<template>
    <HeaderNav />
    <main>
        <ServicesSection />
        <CharacteristicsSection />
        <TechnologiesSection />
        <EquipmentAbout />
        <TickerAbout />
        <MapSection />
    </main>
    <FooterNav />
</template>

<script>
import { defineComponent } from 'vue';
import HeaderNav from '@/modules/layout/default/components/header-nav/HeaderNav.vue';
import FooterNav from '@/modules/layout/default/components/footer-nav/FooterNav.vue';
import {
    CharacteristicsSection,
    ServicesSection,
    TechnologiesSection,
    EquipmentAbout,
    TickerAbout,
    MapSection,
} from '../features/components';

export default defineComponent({
    components: {
        HeaderNav,
        FooterNav,
        CharacteristicsSection,
        ServicesSection,
        TechnologiesSection,
        EquipmentAbout,
        TickerAbout,
        MapSection,
    },
});
</script>
