import {
    ScheduleView,
    PathView,
} from '@/modules/employees/features';

const SCHEDULE_LOCATIONS = [
    'pervomaisky',
    'pakhomovka',
    'airport',
    'amurskaya',
    'automation',
    'chkalovsky',
    'lenin',
    'luzino',
    'maluntseva',
    'railway',
];

export const scheduleRoutes = [
    {
        name: 'schedule',
        path: '/employees/schedule',
        component: ScheduleView,
    },
    ...SCHEDULE_LOCATIONS.map((location) => ({
        name: location,
        path: `/employees/schedule/${location}`,
        component: PathView,
    })),
];
