export const ERROR_MESSAGES = Object.freeze({
    FETCH_DEBTS_ANY: 'Ошибка при получении данных о задолженностях сотрудника. Пожалуйста, попробуйте позже.',
    FETCH_EMPLOYEES_BY_BIRTHDAYS: 'Ошибка при получении данных о именинниках. Пожалуйста, попробуйте позже.',
    FETCH_DEBTS_DOCS: 'Ошибка при получении данных о документообороте сотрудника. Пожалуйста, попробуйте позже.',
    FETCH_TOTAL: 'Ошибка при получении общей суммы задолженностей сотрудника. Пожалуйста, попробуйте позже.',
    FETCH_VACATION_DAYS: 'Ошибка при получении данных о накопленных днях отпуска сотрудника. Пожалуйста, попробуйте позже.',
    FETCH_DUTIES: 'Ошибка при загрузке должностных обязанностей сотрудника. Пожалуйста, попробуйте позже.',
    FETCH_WORK_SCHEDULE: 'Ошибка при загрузке графика сотрудника. Пожалуйста, попробуйте позже.',
    FETCH_MANAGEMENT_CONTACTS: 'Ошибка при загрузке контактов руководства. Пожалуйста, попробуйте позже.',
    DOWNLOAD_INSTRUCTIONS: 'Ошибка при скачивании инструкции. Пожалуйста, попробуйте позже.',
    COPY: 'Ошибка при копировании. Пожалуйста, попробуйте позже.',
});
