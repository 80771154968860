import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index.js';
import { clickOutside } from './shared/directives/click-outside';

const app = createApp(App);

app.use(router);

app.mount('#app');
app.directive('click-outside', clickOutside);
