<template>
    <section class="section_emploees">
        <div class="header_emploee">
            <p class="characteristics_title_meter">
                Передовик
            </p>
            <div class="box_button_header_emploee">
                <div class="box_button_back_emploee">
                    <RouterLink
                        to="/employees"
                        class="button_standart"
                    >
                        Вернуться
                    </RouterLink>
                </div>
                <ModalHeader />
            </div>
        </div>
        <div class="section_advanced">
            <div class="container_advanced">
                <AdvancedBox
                    v-for="(employee, index) in employees"
                    :key="index"
                    :employee="employee"
                />
            </div>
            <div class="container_advanced_text">
                <p class="characteristics_indentation_text color_orange">
                    Спасибо за ваш труд!
                </p>
                <p class="font_16_500_Montserrat_SemiBold">
                    {{ thankYouMessage }}
                </p>
            </div>
        </div>
    </section>
</template>

<script>
import { defineComponent, ref } from 'vue';
import ModalHeader from '@/shared/components/modal-header/ModalHeader.vue';
import {
    AdvancedBox,
} from './components';

export default defineComponent({
    components: { ModalHeader, AdvancedBox },
    setup () {
        const employees = ref(Array(9).fill().map(() => ({
            name: 'Имя Фамилия',
            achievement: 'Полученное достижение',
            image: require('@/assets/background/men.png'),
        })));

        const thankYouMessage = `Золотые руки и светлая, грамотная голова всегда в почёте. Где бы
        человек ни находился, он найдёт себе применение и заслужит уважение.
        Примите сердечную благодарность за проделанную работу, выполненную
        профессионально, на самом высоком уровне. Пусть удача сопутствует вам
        во всех начинаниях!
        \n\n
        Трудитесь, развивайтесь, желаем вам процветания и успехов!
        \n\n
        Директор ЛК "Шкуренко"`;

        return {
            employees,
            thankYouMessage,
        };
    },
});
</script>
