export class StorageService {
    constructor (storage) {
        this.storage = storage;
    }

    setCached (key, value, lifetime = 5 * 60 * 1000) {
        const cacheData = {
            data: value,
            timestamp: Date.now(),
            lifetime,
        };

        return this.set(key, cacheData);
    }

    getCached (key) {
        const cached = this.get(key);

        if (!cached) return null;

        const { data, timestamp, lifetime } = cached;
        const isExpired = Date.now() - timestamp > lifetime;

        if (isExpired) {
            this.remove(key);

            return null;
        }

        return data;
    }

    clearByPrefix (prefix) {
        try {
            Object.keys(this.storage)
                .filter(key => key.startsWith(prefix))
                .forEach(key => this.remove(key));

            return true;
        } catch (error) {
            console.error('Error clearing by prefix:', error);

            return false;
        }
    }

    // Сохранение данных
    set (key, value) {
        try {
            const serializedValue = typeof value === 'object' ?
                JSON.stringify(value) :
                String(value);

            this.storage.setItem(key, serializedValue);

            return true;
        } catch (error) {
            console.error('Error saving to storage:', error);

            return false;
        }
    }

    // Получение данных
    get (key) {
        try {
            const item = this.storage.getItem(key);

            if (item === null) {
                return null;
            }

            try {
                // Пробуем распарсить как JSON
                return JSON.parse(item);
            } catch {
                // Если не получилось распарсить как JSON, возвращаем как есть
                return item;
            }
        } catch (error) {
            console.error('Error reading from storage:', error);

            return null;
        }
    }

    // Удаление конкретного ключа
    remove (key) {
        try {
            this.storage.removeItem(key);

            return true;
        } catch (error) {
            console.error('Error removing from storage:', error);

            return false;
        }
    }

    // Очистка всего хранилища
    clear () {
        try {
            this.storage.clear();

            return true;
        } catch (error) {
            console.error('Error clearing storage:', error);

            return false;
        }
    }

    // Проверка наличия ключа
    has (key) {
        return this.get(key) !== null;
    }
}
