export const errorHandler = {
    handle (error, defaultMessage = 'Произошла неизвестная ошибка') {
        // Если это наша API ошибка
        if (error?.name === 'ApiError') {
            return error.message;
        }

        // Если это стандартная ошибка JS
        if (error instanceof Error) {
            return error.message;
        }

        // Если это строка
        if (typeof error === 'string') {
            return error;
        }

        return defaultMessage;
    },
};
