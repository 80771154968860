import MainPages from '@/modules/layout/views/MainPages.vue';
import AboutView from '@/modules/about/views/AboutView.vue';
import VacanciesView from '@/modules/vacancies/views/VacanciesView.vue';

export const mainRoutes = [
    {
        name: 'main',
        path: '/',
        component: MainPages,
    },
    {
        name: 'about',
        path: '/about',
        component: AboutView,
    },
    {
        name: 'vacancies',
        path: '/vacancies',
        component: VacanciesView,
    },
];
