import { AUTH_TYPES } from '@/modules/auth/features/employee-auth/constants';

const adminRoutes = [
    {
        path: '/admin',
        component: () => import('@/modules/admin/views/AdminView.vue'),
        children: [
            {
                path: '',
                redirect: '/admin/dashboard',
                meta: { requiresAdminAuth: true },
            },
            {
                path: 'dashboard',
                component: () =>
                    import(
                        '@/modules/admin/features/dashboard/DashboardView.vue'
                    ),
                meta: { requiresAdminAuth: true },
            },
            {
                path: 'users',
                component: () =>
                    import('@/modules/admin/features/users/UsersView.vue'),
                meta: { requiresAdminAuth: true },
            },
            {
                path: 'vacancies',
                component: () =>
                    import(
                        '@/modules/admin/features/vacancies/VacanciesView.vue'
                    ),
                meta: { requiresAdminAuth: true },
            },
            {
                path: 'leaders',
                component: () =>
                    import('@/modules/admin/features/leaders/LeadersView.vue'),
                meta: { requiresAdminAuth: true },
            },
            {
                path: 'work-schedule',
                component: () =>
                    import(
                        '@/modules/admin/features/work-schedule/WorkScheduleView.vue',
                    ),
                meta: { requiresAdminAuth: true },
            },
            {
                path: 'work-rest-mode',
                component: () =>
                    import(
                        '@/modules/admin/features/work-rest-mode/WorkRestModeView.vue',
                    ),
                meta: { requiresAdminAuth: true },
            },
            {
                path: 'management-contacts',
                component: () =>
                    import(
                        '@/modules/admin/features/management-contacts/ManagementContactsView.vue',
                    ),
                meta: { requiresAdminAuth: true },
            },
            {
                path: 'employee-duties',
                component: () =>
                    import(
                        '@/modules/admin/features/employee-duties/EmployeeDutiesView.vue',
                    ),
                meta: { requiresAdminAuth: true },
            },
        ],
        meta: { requiresAdminAuth: true },
    },
    {
        path: '/admin/login',
        component: () => import('@/modules/auth/views/LoginView.vue'),
        props: {
            isAdmin: true,
        },
        meta: { requiresAdminAuth: false },
    },
    {
        path: '/admin/set-pin',
        name: 'AdminSetPin',
        component: () =>
            import(
                '@/modules/auth/features/employee-auth/components/SetPin.vue'
            ),
        props: {
            authType: AUTH_TYPES.ADMIN,
        },
        meta: { requiresAdminAuth: false },
    },
    {
        path: '/admin/enter-pin',
        name: 'AdminEnterPin',
        component: () =>
            import(
                '@/modules/auth/features/employee-auth/components/EnterPin.vue'
            ),
        props: {
            authType: AUTH_TYPES.ADMIN,
        },
        meta: { requiresAdminAuth: false },
    },
];

export default adminRoutes;
