import {
    adminHttp,
    employeeHttp,
} from '@/shared/services/http/instances';
import { API_ENDPOINTS } from '@/shared/constants';

export const authService = {
    async login (credentials, isAdmin = false) {
        const http = isAdmin ? adminHttp : employeeHttp;
        const endpoint = isAdmin ? API_ENDPOINTS.LOGIN_ADMIN : API_ENDPOINTS.LOGIN;

        try {
            return await http.post(endpoint, credentials, { requiresAuth: false });
        } catch (error) {
            throw new Error('Ошибка авторизации');
        }
    },
};
