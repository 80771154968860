<template>
    <HeaderNav />
    <main>
        <AboutSection />
    </main>
    <FooterNav />
</template>

<script>
import { defineComponent } from 'vue';
import {
    HeaderNav,
    FooterNav,
} from '../default/components';
import {
    AboutSection,
} from '@/modules/about/features/components';

export default defineComponent({
    components: {
        HeaderNav,
        FooterNav,
        AboutSection,
    },
});
</script>
