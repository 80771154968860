import {
    AdminAuthService,
    EmployeeAuthService,
} from '@/modules/auth/features/employee-auth/services';

export async function checkAdminAuth (to, from, next) {
    try {
        const isAdminAuthenticated = await AdminAuthService.checkAuth();

        if (to.path === '/admin/login') {
            return isAdminAuthenticated ? next('/admin') : next();
        }

        if (to.meta.requiresAdminAuth) {
            return isAdminAuthenticated ? next() : next('/admin/login');
        }

        return next();
    } catch (error) {
        return to.meta.requiresAdminAuth ? next('/admin/login') : next();
    }
}

export async function checkEmployeeAuth (to, from, next) {
    try {
        const isEmployeeAuthenticated = await EmployeeAuthService.checkAuth();

        if (to.path === '/employee/login') {
            return isEmployeeAuthenticated ? next('/employee/account') : next();
        }

        if (to.meta.requiresEmployeeAuth) {
            return isEmployeeAuthenticated ? next() : next('/employee/login');
        }

        return next();
    } catch (error) {
        return to.meta.requiresEmployeeAuth ? next('/employee/login') : next();
    }
}
