import { adminHttp } from '@/shared/services/http/instances';
import { API_ENDPOINTS } from '@/shared/constants';
import { AdminAuthService, EmployeeAuthService } from './index';
import { AUTH_TYPES } from '../constants';

export class PinService {
    constructor (authType = AUTH_TYPES.EMPLOYEE) {
        this.authType = authType;
        this.authService = this.getAuthService();
        this.http = adminHttp;
    }

    getAuthService () {
        return this.authType === AUTH_TYPES.ADMIN
            ? AdminAuthService
            : EmployeeAuthService;
    }

    async setPin (externalCode, pin, remember) {
        try {
            const response = await this.http.post(
                API_ENDPOINTS.SET_PIN,
                {
                    externalCode,
                    password: pin,
                    authType: this.authType,
                },
                { requiresAuth: false },
            );

            if (response.status === 'success') {
                this.authService.saveAuth(
                    {
                        accessToken: response.tokens.accessToken,
                        refreshToken: response.tokens.refreshToken,
                        user: response.employee,
                    },
                    remember,
                );

                return { success: true };
            }

            return { success: false, error: 'Не удалось установить PIN-код' };
        } catch (error) {
            console.error('Ошибка при установке PIN-кода:', error);

            return { success: false, error: 'Ошибка при установке PIN-кода' };
        }
    }

    async checkPin (externalCode, pin, remember) {
        try {
            const response = await this.http.post(
                API_ENDPOINTS.CHECK_PIN,
                {
                    externalCode,
                    password: pin,
                    authType: this.authType,
                },
                { requiresAuth: false },
            );

            if (response.status === 'success') {
                this.authService.saveAuth(
                    {
                        accessToken: response.tokens.accessToken,
                        refreshToken: response.tokens.refreshToken,
                        user: response.employee,
                    },
                    remember,
                );

                return { success: true };
            }

            return { success: false, error: 'Неверный PIN-код' };
        } catch (error) {
            console.error('Ошибка при проверке PIN-кода:', error);

            return { success: false, error: 'Ошибка при проверке PIN-кода' };
        }
    }
}
