export class ApiError extends Error {
    constructor (message, status, details = null) {
        super(message);
        this.name = 'ApiError';
        this.status = status;
        this.details = details;
    }

    toString () {
        return this.message;
    }
}
